import { blue, pink, purple } from "@mui/material/colors";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
// import { orange } from "@mui/material/colors";
// type Theme = {
//   [key: string]: any;
// };

export const appTheme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
    h6: {
      fontWeight: 700,
      color: "#fff",
    },
    h1: {
      fontWeight: 700,
    },
    subtitle1: {
      fontWeight: "bold",
    },
    h3: {},
    subtitle2: {
      color: "#6941C6",
    },
  },

  palette: {
    primary: {
      main: "#7C57FC",
    },
    secondary: {
      main: "#c7fa56",
    },
    text: {
      secondary: "#fff",
    },
  },
  spacing: 8,
});
