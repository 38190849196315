import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react'
import googleplay from "../../assets/img/store1.png"
import iosStore from "../../assets/img/store2.png"
import phoneMockup from "../../assets/img/frame1.png"

const HowMuchItCost = () => {
    const redirectToAppStore = () => {
        window.location.href =
            "https://apps.apple.com/pk/app/simlesspay-money-transfer/id6466781453";
    };

    const redirectToPlayStore = () => {
        window.location.href =
            "https://play.google.com/store/apps/details?id=com.newsimlesspay&hl=en&gl=US";
    };
    return (
        <Box sx={{ padding: 4, minHeight: '65vh' }}>
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                {/* Text Section */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h3" component={"h2"} gutterBottom>
                        Here’s how much it costs to send money to Nigeria
                    </Typography>

                    <Typography variant="body1" paragraph>
                        All our transfers to Nigeria are free! Instant card transfers, bank transfers, next day transfers… we add zero fees.

                        And we don’t hide fees either.<br></br> The receiver gets the exact amount that our app tells you they’ll get. No hidden fees or no nasty surprises                    </Typography>
                    <Box mt={2}>
                        <div
                        // className="flex px-14 py-8 justify-center lg:justify-start border-none outline-none"
                        >
                            <Button onClick={redirectToPlayStore}>
                                <img src={googleplay} alt="google play" />
                            </Button>
                            <Button onClick={redirectToAppStore}>
                                <img src={iosStore} alt="google play" />
                            </Button>

                            {/* <button className=" mt-3" onClick={redirectToAppStore}>
                                <img src={iosStore} />
                            </button> */}
                        </div>
                        {/* <Button variant="contained" color="warning">
                    Explore on AppGallery
                  </Button> */}
                    </Box>
                </Grid>

                {/* Image Section */}
                <Grid item xs={12} md={6}>
                    <Box sx={{ textAlign: 'center' }}>
                        <img
                            src={phoneMockup}
                            alt="Phone mockup"
                            style={{ width: '100%', maxWidth: 400 }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>


    )
}

export default HowMuchItCost