import { Button, Grid, Typography } from '@mui/material'
import CurrencyConverter from './CurrencyConverter'

const Hero = () => {
    // console.log(scrollToHero);
    const gradientStyle = {
        background:
            "linear-gradient(142deg, #C3F25E 0%, #C3F25E 19.55%,#C3F25E 19.55%,#7E5DF5 79.45%,#7E5DF5 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
    };
    return (
        <Grid container alignItems="center" justifyContent="space-between" style={{ minHeight: '80vh' }} mt={{
            xs: '20%', md: '1%'
        }}>
            <Grid textAlign={{ xs: 'center', md: 'start' }} item xs={12} md={7}>
                <Typography variant="h2" fontWeight="bold" gutterBottom>
                    Send Money to Nigeria with <span style={gradientStyle}>SimlessPay</span>
                </Typography>
                <Typography variant="h3" component="h2" gutterBottom>
                    Instant & Secure Money Transfer to Nigeria
                </Typography>
                <Button
                    onClick={() => document.getElementById('targetElement')?.scrollIntoView({ behavior: 'smooth' })}

                    sx={{
                        display: {
                            xs: 'none',
                            md: 'block'
                        }
                    }}
                    variant="contained" size="large">
                    Send Money Now
                </Button>
            </Grid>
            <Grid item xs={12} md={5}>
                <CurrencyConverter
                />
            </Grid>
        </Grid>
    )
}


export default Hero