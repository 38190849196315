import React from 'react';
import { Stepper, Step, StepLabel, StepContent, Grid, Typography } from '@mui/material';
import phoneMockup from "../../assets/img/FeaturedPerson.png"
const steps = [
    'Choose how much you want to send, and when',
    'Add the receiver\'s details',
    'Choose your payment method and make the transfer!',
];

export default function TransferStepper() {
    const [activeStep, setActiveStep] = React.useState(3);
    return (
        <Grid my={10}>
            <Typography textAlign={"center"} color={"primary"} variant='h3'> It’s as easy as 1, 2, 3</Typography>
            <Grid container justifyContent={"center"} alignItems={"center"} >
                <Grid item container xs={12} sm={6} alignItems={"center"} justifyContent={"center"} >

                    <Stepper activeStep={activeStep} orientation="vertical" >
                        {steps.map((label, index) => (
                            <Step key={label} completed={index < activeStep}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                <Grid justifyContent={"center"} alignItems={"center"} item >
                    <img
                        width={250}
                        src={phoneMockup}// Replace with the path to your phone mockup image
                        alt="Phone mockup"
                    // style={{ maxWidth: 400 }}
                    />
                </Grid>
            </Grid>
        </Grid>

    );
}
