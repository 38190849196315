import React from "react";
import { Grid, Box, Typography, Stack } from "@mui/material";
import Appstore from "../assets/img/Mobile app store badge.png";
import googleImg from "../assets/img/google.png";
import Content from "../assets/img/Content.png";

const CalltoAction = () => {
  return (
    <div>
      <Box
        sx={{
          marginLeft: "10%",
          marginRight: "10%",
          marginBottom: { sm: "15%", xs: "20%" },
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          alignSelf={"center"}
          alignItems={"center"}
        >
          <Grid item xs={12} md={6} lg={6} mt={4} sm={6}>
            <Box>
              <Stack direction={"column"} spacing={{ sm: 5, xs: 3 }}>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: { lg: 40, xl: 80, sm: 30, xs: 25 },
                    fontFamily: "IBM Plex Sans",
                    fontWeight: 500,
                  }}
                >
                  All in one finance for sending money and bills
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: { lg: 20, xl: 40 },
                    fontFamily: "Montserrat",
                    color: "var(--gray-500, #667085)",
                  }}
                >
                  Download now and start transacting
                </Typography>
                <Stack
                  marginTop={0}
                  direction={"row"}
                  spacing={2}
                  sx={{
                    "@media(min-width:600px)": {
                      marginTop: 0,
                    },
                  }}
                >
                  <a
                    href="https://apps.apple.com/pk/app/simlesspay-money-transfer/id6466781453"
                    target="_blank"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    <Box
                      component="img"
                      sx={{
                        width: { sm: "90px", xs: "80px" },
                        height: { sm: "30px", xs: "30px" },
                        maxHeight: { xl: 900, lg: 899 },
                        maxWidth: { xl: 300 },
                      }}
                      alt="The house from the offer."
                      src={Appstore}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.newsimlesspay"
                    target="_blank"
                    style={{ cursor: "pointer" }}
                  >
                    <Box
                      component="img"
                      sx={{
                        width: { sm: "90px", xs: "80px" },
                        height: { sm: "30px", xs: "30px" },
                        maxHeight: { xl: 900, lg: 899 },
                        maxWidth: { xl: 300 },
                      }}
                      alt="The house from the offer."
                      src={googleImg}
                    />
                  </a>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box
              component="img"
              sx={{ width: { sm: "80%", xs: "80%" } }}
              alt="The house from the offer."
              src={Content}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CalltoAction;
