import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import DrwaerComponent from "../DrawerComponent";
import Navbar from "../Navbar";
import { Typography } from "@mui/material";
import HeroImg from "../../assets/img/AboutImg/HeroImgAbout.png";

const Hero = () => {
  return (
    <div>
      <Box
        sx={{
          flexGrow: 1,
          px: { sm: 20, xs: 4 },
          py: 10,
          paddingTop: "10rem",
          paddingBottom: "10rem",
        }}
      >
        {/* <Typography
          sx={{
            color: "#7C57FC",
            fontWeight: 600,
            lineHeight: "24px",
            fontFamily: "Montserrat",
          }}
        >
          Current as of 20 Jan 2022
        </Typography> */}
        <Grid container spacing={2} mb={{ md: 10, sm: 7, xs: 5 }}>
          {/* <Grid item sm={12} md={4} xs={12}>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#667085",
                lineHeight: { sm: "30px", xs: "20px" },

                fontWeight: "400px",
                fontFamily: "Montserrat",
                fontSize: { sm: "18px", xs: "16px" },
              }}
            >
              By accessing our website, you are agreeing to be bound by these
              terms of service, and agree that you are responsible for
              compliance with any applicable local laws.
            </Typography>
          </Grid> */}

          <div>
            <h1>Privacy Policy</h1>
            <section>
              <h2>Introduction</h2>
              <p>
                This policy describes how we collect, store, transfer and use
                personal data. It tells you about your privacy rights and how
                the law protects you.{" "}
              </p>
              <ul style={{}}>
                <li>
                  In the context of the law and this notice, ‘personal data’ is
                  information that clearly identifies you as an individual or
                  which could be used to identify you if combined with other
                  information. Acting in any way on personal data is referred to
                  as ‘processing’.
                </li>
                <li>
                  This policy applies to: Visitors to our Website, Customers and
                  users of our services, Prospective customers, People who
                  contact us with enquiries and anyone whose personal data we
                  process in connection with the provision, or possible
                  provision, of services by us to our customers, including as a
                  result of communications and interactions with us, through the
                  use of our services directly or indirectly (including
                  processing payments) and as necessary in order to enable us to
                  comply with our legal and regulatory obligations.
                </li>
                <li>
                  This notice also applies to personal data collected through
                  our website and through social media platforms:
                  <ul>
                    <li>Facebook</li>
                    <li>Instagram</li>
                    <li>Twitter</li>
                    <li>LinkedIn</li>
                    <li>and online retail platforms.</li>
                  </ul>
                </li>
                <li>
                  Except as set out below, we do not share, or sell, or disclose
                  to a third party, any information collected through our
                  website or otherwise.
                </li>
              </ul>
              <br />
              <p>
                Your privacy is important to SimlessPay Ltd (
                <span>"SimlessPay", "we", "us" and "our"</span>). We are
                committed to protecting your personal data and being transparent
                about the personal data we hold and use and, wherever possible,
                giving you control over how we use your personal data.
              </p>
            </section>
            <section>
              <p>
                SimlessPay Limited is a company incorporated in England with
                company number 12613378, and our registered office is at
                Cariocca Business Park, 2, Sawley Road Manchester, England, M40
                8BB
              </p>
            </section>
            <br />
            <section>
              <p>The information we process about you includes information:</p>
              <ul>
                <li>you have directly provided to us</li>
                <li>
                  that we gather from third party databases and service
                  providers
                </li>
                <li>
                  as a result of monitoring how you use our website or our
                  services
                </li>
              </ul>
            </section>
            <br />
            <section>
              <p>
                When you use our website and our services, for example (when you
                create an account on our website or app) we ask you to provide
                personal data. This can be categorized into the following
                groups:{" "}
              </p>
              <ul>
                <li>
                  personal identifiers, such as your first and last names, your
                  title, marital status, gender and your date of birth
                </li>
                <li>
                  contact information, such as your email address, your
                  telephone number and your postal addresses for billing,
                  delivery and communication
                </li>
                <li>
                  account information, including your username and password
                </li>
                <li>
                  payment information, such as a debit or credit card number and
                  expiry date and bank account details
                </li>
                <li>
                  records of communication between us including messages sent
                  through our website, email messages and telephone
                  conversations
                </li>
                <li>
                  marketing preferences that tell us what types of marketing you
                  would like to receive
                </li>
                <li>
                  technical data includes internet protocol (IP) address, your
                  login data, browser type and version, time zone setting and
                  location, browser plug-in types and versions, operating system
                  and platform, and other technology on the devices you use to
                  access this website.
                </li>
                <li>
                  profile data includes your username and password, feedback and
                  survey responses.
                </li>
                <li>
                  usage data includes information about how you use our website,
                  the Simless Platform and services.
                </li>
              </ul>
              <p>In addition, we may also process:</p>
              <ul>
                <li>
                  documentation that confirms your identity, such as an image of
                  your passport or driver’s license.
                </li>
                <li>
                  documentation that confirms your address , such as a tenancy
                  agreement or rental contract
                </li>
                <li>
                  documentation that confirms your employment, such as recent
                  payslips.
                </li>
              </ul>
            </section>
            <section>
              <h3>Types of personal data we collect from third parties</h3>
              <p>
                We confirm some of the information you provide to us directly
                using data from other sources. We also add to the information we
                hold about you, sometimes to remove the need for you to provide
                it to us and sometimes in order to be able to assess the quality
                of the services we offer.{" "}
              </p>
              <p>
                The additional information we collect can be categorised as
                follows:{" "}
              </p>
              <ul>
                <li>information that confirms your identity</li>
                <li>information that confirms your contact information</li>
                <li>reviews and feedback about our business</li>
              </ul>
            </section>
            <section>
              <h3>
                Types of personal data we collect from your use of our services{" "}
              </h3>
              <p>By using our website and our services, we process: </p>
              <ul>
                <li>
                  your username and password and other information used to
                  access our website and our services
                </li>
                <li>
                  information you contribute to our community, including reviews
                </li>
                <li>your replies to polls and surveys</li>
                <li>
                  technical information about the hardware and the software you
                  use to access our website and use our services, including your
                  Internet Protocol (IP) address, your browser type and version
                  and your device’s operating system
                </li>
                <li>
                  usage information, including the frequency you use our
                  services, the pages of our website that you visit, whether you
                  receive messages from us and whether you reply to those
                  messages
                </li>
                <li>
                  transaction information that includes the details of the
                  products services you have bought from us and payments made to
                  us for those services
                </li>
                <li>
                  your preferences to receive marketing from us; how you wish to
                  communicate with us; and responses and actions in relation to
                  your use of our services.
                </li>
              </ul>
            </section>
            <section>
              <h3>How we obtain your personal information</h3>
              <p>
                We use different methods to collect data from and about you
                including through:{" "}
              </p>
              <ul>
                <li>
                  Direct interactions - You may give us your personal
                  information about yourself by filling in forms on our website
                  or app or otherwise or by corresponding with us by phone,
                  email, or completing forms for or at events, setting up an
                  account with us, or other means. This includes where you
                  provide your personal data to us in order to receive
                  information or Services from us, create an account with us
                  using our app, give us feedback or contact us. As you interact
                  with our website, we will automatically collect technical data
                  about your equipment, browsing actions and patterns. We
                  collect this personal data by using cookies and other similar
                  technologies. We may also receive technical data about you if
                  you visit other websites employing our cookies.
                </li>
                <li>
                  Third parties database or service providers or publicly
                  available sources. We may also receive personal information
                  about you from various third parties such as marketing
                  agencies, market research companies, public websites and
                  public agencies.
                </li>
              </ul>
            </section>
            <section>
              <h3>Our use of aggregated information</h3>
              <p>
                We may aggregate anonymous information such as statistical or
                demographic data for any purpose. Anonymous information is that
                which does not identify you as an individual. Aggregated
                information may be derived from your personal data but is not
                considered as such in law because it does not reveal your
                identity. For example, we may aggregate usage information to
                assess whether a feature of our website is useful. However, if
                we combine or connect aggregated information with your personal
                data so that it can identify you in any way, we treat the
                combined information as personal data, and it will be used in
                accordance with this privacy notice.{" "}
              </p>
            </section>
            <section>
              <h3>Special personal data</h3>
              <p>
                Special personal data is data about your race or ethnicity,
                religious or philosophical beliefs, sex life, sexual
                orientation, political opinions, trade union membership,
                information about your health and genetic and biometric data. We
                may collect special personal data about you if there is a lawful
                basis on which to do so.
              </p>
            </section>
            <section>
              <h3>The bases on which we process information about you </h3>
              <p>
                The law requires us to determine under which of six defined
                bases we process different categories of your personal data, and
                to notify you of the basis for each category. If a basis on
                which we process your personal data is no longer relevant then
                we shall immediately stop processing your data. If the basis
                changes then if required by law we shall notify you of the
                change and of any new basis under which we have determined that
                we can continue to process your information.
              </p>
            </section>
            <section>
              <h3>
                Information we process because we have a contractual obligation
                with you
              </h3>
              <p>
                When you create an account on our website, buy a product or
                service from us, or otherwise agree to our terms and conditions,
                a contract is formed between you and us. In order to carry out
                our obligations under that contract we must process the
                information you give us. Some of this information may be
                personal data. We may use it in order to:
              </p>
              <ul>
                <li>
                  verify your identity for security purposes when you use our
                  services
                </li>
                <li>sell products to you</li>
                <li>provide you with our services</li>
                <li>
                  provide you with suggestions and advice on products, services
                  and how to obtain the most from using our website
                </li>
              </ul>
              <p>
                We process this information on the basis there is a contract
                between us, or that you have requested we use the information
                before we enter into a legal contract. We shall continue to
                process this information until the contract between us ends or
                is terminated by either party under the terms of the contract.
              </p>
            </section>
            <section>
              <h3>Information we process with your consent </h3>
              <p>
                Through certain actions when otherwise there is no contractual
                relationship between us, such as when you browse our website or
                ask us to provide you more information about our business,
                including ( job opportunities and ) our products and services,
                you provide your consent to us to process information that may
                be personal data. Wherever possible, we aim to obtain your
                explicit consent to process this information, for example, we
                ask you to agree to our use of nonessential cookies when you
                access our website. If you have given us explicit permission to
                do so, we may from time to time pass your name and contact
                information to selected associates whom we consider may provide
                services or products you would find useful. We continue to
                process your information on this basis until you withdraw your
                consent or it can be reasonably assumed that your consent no
                longer exists. You may withdraw your consent at any time by
                instructing us at https://simlesspay.com/. However, if you do
                so, you may not be able to use our website or our services
                further. We aim to obtain and keep your consent to process your
                information. However, while we take your consent into account in
                decisions about whether or not to process your personal data,
                the withdrawal of your consent does not necessarily prevent us
                from continuing to process it. The law may allow us to continue
                to process your personal data, provided that there is another
                basis on which we may do so. For example, we may have a legal
                obligation to do so.{" "}
              </p>
            </section>
            <section>
              <h3>
                Information we process for the purposes of legitimate interests
              </h3>
              <p>
                We may process information on the basis there is a legitimate
                interest, either to you or to us, of doing so. Where we process
                your information on this basis, we do after having given careful
                consideration to:
              </p>
              <ul>
                <li>
                  whether the same objective could be achieved through other
                  means
                </li>
                <li>
                  whether processing (or not processing) might cause you harm
                </li>
                <li>
                  whether you would expect us to process your data, and whether
                  you would, in the round, consider it reasonable to do so
                </li>
              </ul>
              <p>
                For example, we may process your data on this basis for the
                purposes of:{" "}
              </p>
              <ul>
                <li>improving our services</li>
                <li>
                  record-keeping for the proper and necessary administration of
                  our company SimlessPay Limited in preventing fraudulent use of
                  our services.
                </li>
                <li>
                  exercising our legal rights, including to detect and prevent
                  fraud and to protect our intellectual property
                </li>
                <li>
                  insuring against or obtaining professional advice that is
                  required to manage SimlessPay Ltd financial risk.
                </li>
                <li>
                  protecting your interests where we believe we have a duty to
                  do so.
                </li>
              </ul>
            </section>
            <section>
              <h3>Information we process because we have a legal obligation</h3>
              <p>
                Sometimes, we must process your information in order to comply
                with a statutory obligation. For example, we may be required to
                give information to legal authorities if they so request or if
                they have the proper authorisation such as a search warrant or
                court order. This may include your personal data.{" "}
              </p>
            </section>
            <section>
              <h3>How and when we process your personal data</h3>
              <p>
                We do not share or disclose to a third party, any information
                collected through our website.
              </p>
            </section>
            <section>
              <h3>Information you provide</h3>
              <p>
                Our website allows you to post information with a view to that
                information being read, copied, downloaded, or used by other
                people. For example, when you leave a review or post a message
                on our website, we reasonably assume that you consent for the
                message to be seen by others. We may include your username with
                your message, and your message may contain information that is
                personal data. Other examples include:
              </p>
              <ul>
                <li>
                  Clicking on an image next to another user message to convey
                  your agreement, disagreement or thanks.
                </li>
                <li>Tagging an image</li>
              </ul>
              <p>
                In posting personal data, it is up to you to satisfy yourself
                about the privacy level of every person who might use it.
                SimlessPay Ltd do not specifically use this information except
                to allow it to be displayed or shared. We do store it, and we
                reserve a right to use it in the future in any way we decide.
                Once your information enters the public domain, we have no
                control over what any individual third party may do with it. We
                accept no responsibility for their actions at any time. Provided
                your request is reasonable and there is no legal basis for us to
                retain it, then at our discretion we may agree to your request
                to deleteclass personal data that you have posted. You can make
                a request by contacting us at Sport at
                <a href="https://simlesspay.com"> simlesspay.com</a>
              </p>
            </section>
            <section>
              <h3>Payment information</h3>
              <p>
                We store information about your debit or credit card or other
                means of payment when you first provide it to us. We store this
                information (at your request) in order to make repeat purchasing
                of goods and services easier next time you visit our website. We
                also store it to help us prevent fraud. We take the following
                measures to protect your payment information:{" "}
              </p>
              <ul>
                <li>
                  We keep your payment information encrypted on our servers
                </li>
                <li>
                  We do not keep all your payment information so as:
                  <ul>
                    <li></li>
                    to prevent the possibility of our duplicating a transaction
                    without a new instruction from you.
                    <li>
                      to prevent any other third party from carrying out a
                      transaction without your consent.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Access to your to your payment information is restricted to
                authorised staff only. If we ask you questions about your
                payment information, we only show the last four digits of the
                debit or credit card number, so that we can identify the means
                of payment to which we refer.{" "}
              </p>
            </section>
            <section>
              <h3>Job application and employment </h3>
              <p>
                If you send us information in connection with a job application,
                we may keep it for up to 3 years in case we decide to contact
                you at a later date. If we employ you, we collect information
                about you and your work from time to time throughout the period
                of your employment. This information will be used only for
                purposes directly relevant to your employment. After your
                employment has ended, we will keep your file for 6 years before
                destroying or deleting it.{" "}
              </p>
            </section>
            <section>
              <h3>Information obtained from third parties </h3>
              <p>
                Although we do not disclose your personal data to any third
                party (except as set out in this notice), we sometimes receive
                data that is indirectly made up from your personal data from
                third parties whose services we use. No such information is
                personally identifiable to you.{" "}
              </p>
            </section>
            <section>
              <h3>Third party advertising on our website</h3>
              <p>
                Third parties may advertise on our website. In doing so, those
                parties, their agents or other companies working for them may
                use technology that automatically collects information about you
                when their advertisement is displayed on our website. They may
                also use other technology such as cookies or JavaScript to
                personalise the content of, and to measure the performance of
                their adverts. We do not have control over these technologies or
                the data that these parties obtain. Accordingly, this privacy
                notice does not cover the information practices of these third
                parties.
              </p>
            </section>
            <section>
              <h3>Credit reference </h3>
              <p>
                To assist in combating fraud, we share information with credit
                reference agencies, so far as it relates to clients or customers
                who instruct their credit card issuer to cancel payment to us
                without having first provided an acceptable reason to us and
                given us the opportunity to refund their money.
              </p>
            </section>
            <section>
              <h3>Service providers and business partners </h3>
              <p>
                We may share your personal data with businesses that provide
                services to us, or with business partners. As examples:
              </p>
              <ul>
                <li>
                  we may pass your payment information to our payment service
                  provider to take payments from you
                </li>
                <li>
                  we may use fraud prevention agencies and credit reference
                  agencies to verify your identity and we may pass your
                  information to those agencies if we strongly suspect fraud on
                  our website
                </li>
                <li>
                  we may pass your contact information to advertising agencies
                  to use to promote our services to you
                </li>
              </ul>
            </section>
            <section>
              <h3>Referral partners</h3>
              <p>
                This is information given to us by you in your capacity as an
                affiliate of us or as a referral partner. It allows us to
                recognise visitors that you have referred to us, and to credit
                to you commission due for such referrals. It also includes
                information that allows us to transfer commission to you. The
                information is not used for any other purpose. We undertake to
                preserve the confidentiality of the information and of the terms
                of our relationship. We expect any affiliate or partner to agree
                to reciprocate this policy.{" "}
              </p>
            </section>
            <section>
              <h3>Use of information we collect through automated systems</h3>
              <div>
                <h4>1. Cookies</h4>
                <p>
                  Cookies are small text files that are placed on your
                  computer’s hard drive by your web browser when you visit a
                  website that uses them. They allow information gathered on one
                  web page to be stored until it is needed for use at a later
                  date. They are commonly used to provide you with a
                  personalised experience while you browse a website, for
                  example, allowing your preferences to be remembered. They can
                  also provide core functionality such as security, network
                  management, and accessibility; record how you interact with
                  the website so that the owner can understand how to improve
                  the experience of other visitors; and serve you advertisements
                  that are relevant to your browsing history. Some cookies may
                  last for a defined period of time, such as one visit (known as
                  a session), one day or until you close your browser. Your web
                  browser should allow you to delete any cookie you choose. It
                  should also allow you to prevent or limit their use. Your web
                  browser may support a plug-in or add-on that helps you manage
                  which cookies you wish to allow to operate. The law requires
                  you to give explicit consent for use of any cookies that are
                  not strictly necessary for the operation of a website. When
                  you first visit our website, we ask you whether you wish us to
                  use cookies. If you choose not to accept them, we shall not
                  use them for your visit except to record that you have not
                  consented to their use for any other purpose. If you choose
                  not use cookies or prevent their use through your browser
                  settings, you may not be able to use all the functionality of
                  our website. We use cookies in the following ways:{" "}
                </p>
                <ul>
                  <li>to track how you use our website</li>
                  <li>
                    to record whether you have seen specific messages we display
                    on our website
                  </li>
                  <li>to keep you signed in to our website</li>
                  <li>
                    to record your answers to surveys and questionnaires on our
                    site while you complete them
                  </li>
                  <li>
                    to record the conversation thread during a live chat with
                    our support team
                  </li>
                  <li>
                    We provide more information about the cookies we use in our
                    cookie policy.
                  </li>
                </ul>
              </div>
              <div>
                <h4>2. Personal identifiers from your browsing activity </h4>
                <p>
                  Requests by your web browser to our servers for web pages and
                  other content on our website are recorded We record
                  information such as your geographical location, your Internet
                  service provider and your IP address. We also record
                  information about the software you are using to browse our
                  website, such as the type of computer or device and the screen
                  resolution. We use this information in aggregate to assess the
                  popularity of the webpages on our website and how we perform
                  in providing content to you. If combined with other
                  information we know about you from previous visits, the data
                  possibly could be used to identify you personally, even if you
                  are not signed in to our website.{" "}
                </p>
              </div>
              <div>
                <h4>3. Re-marketing </h4>
                <p>
                  Re-marketing involves placing a ‘tracking technology’ such as
                  a cookie, a ‘web beacon’ (also known as an ‘action tag’ or a
                  ‘single-pixel GIF’) to track which pages you visit and to
                  serve you relevant adverts for our services when you visit
                  some other website. The benefit of remarketing technology is
                  that we can provide you with more useful and relevant adverts,
                  and not show you ones repeatedly that you may have already
                  seen. We may use a third-party advertising service to provide
                  us with re-marketing services from time to time. If you have
                  consented to our use of such tracking technologies, you may
                  see advertisements for our products and services on other
                  websites. We do not provide your personal data to advertisers
                  or to third-party re-marketing service providers. However, if
                  you are already a member of a website whose affiliated
                  business provides such services, that affiliated business may
                  learn of your preferences in relation to your use of our
                  website.{" "}
                </p>
              </div>
            </section>
            <section>
              <h3>Other matters</h3>
              <div>
                <h5>Your rights</h5>
                <p>
                  The law requires us to tell you about your rights and our
                  obligations to you in regard to the processing and control of
                  your personal data. We do this now, by requesting that you
                  read the information provided at{" "}
                  <a
                    href="http://www.knowyourprivacyrights.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.knowyourprivacyrights.org
                  </a>
                </p>
              </div>
              <div>
                <h5>
                  Delivery of services using third party communication software
                </h5>
                <p>
                  With your consent, we may communicate using software provided
                  by a third party such as Facebook, WhatsApp, Apple, Google.
                  Such methods of communication should secure your personal data
                  using encryption and other technologies. The providers of such
                  software should be in compliance of all applicable privacy
                  laws, rules and regulations, including the GDPR. If you have
                  any concerns using a particular software for communication
                  please let us know.
                </p>
              </div>
              <div>
                <h5>Data may be processed outside the UK</h5>
                <p>
                  Our websites are hosted in the United Kingdom We may also use
                  outsourced services in countries outside the UK from time to
                  time in other aspects of our business. Accordingly data
                  obtained within the UK or any other country could be processed
                  outside the UK. We use the following safeguards with respect
                  to data transferred outside the UK:{" "}
                </p>
                <ul>
                  <li>
                    The data protection clauses in our contracts with data
                    processors include transfer clauses written by or approved
                    by a supervisory authority.
                  </li>
                  <li>We comply with a code of conduct approved by FCA</li>
                  <li>
                    We are certified under an approved certification mechanism
                    as provided by the FCA
                  </li>
                </ul>
              </div>
            </section>
            <section>
              <h5>Control over your own information</h5>
              <p>
                It is important that the personal data we hold about you is
                accurate and up to date. Please inform us if your personal data
                changes. At any time, you may contact us to request that we
                provide you with the personal data we hold about you You may at
                any time review or update personally identifiable information
                that we hold about you, by signing in to your account on our
                website (https://Simless.com) To obtain a copy of any
                information that is not provided on our website you should
                contact us to make that request. When we receive any request to
                access, edit or delete personal data we first take reasonable
                steps to verify your identity before granting you access or
                otherwise taking any action. This is important to safeguard your
                information. Please be aware that we are not obliged by law to
                provide you with all personal data we hold about you, and that
                if we do provide you with information, the law allows us to
                charge for such provision if doing so incurs costs for us. After
                receiving your request, we will tell you when we expect to
                provide you with the information, and whether we require any fee
                for providing it to you. If you wish us to remove personally
                identifiable information from our website, you should contact us
                to make your request. This may limit the service we can provide
                to you. We remind you that we are not obliged by law to delete
                your personal data or to stop processing it simply because you
                do not consent to us doing so. While having your consent is an
                important consideration as to whether to process it, if there is
                another legitimate basis on which we may process it, we may do
                so on that basis.{" "}
              </p>
            </section>
            <section>
              <h3>Communicating with us </h3>
              <p>
                When you contact us, whether by telephone, through our website
                or by email, we collect the data you have given to us in order
                to reply with the information you need. We record your request
                and our reply in order to increase the efficiency of our
                business. SimlessPay Ltd may keep identifiable information
                associated with your message such as your name and email address
                so as to be able to track our communications with you to provide
                an excellent quality service.
              </p>
            </section>
            <section>
              <h3>Complaining </h3>
              <p>
                If you are not happy with our privacy policy, or if you have any
                complaint, then you should tell us. When we receive a complaint,
                we record the information you have given to us on the basis of
                consent. We use that information to resolve your complaint. We
                aim to investigate all complaints relating to user service
                experience. However, we may not be able to do so as soon as a
                complaint is made. We aim to answer all complaints within 24hr
                period. If we think your complaint is vexatious or without any
                basis, we shall not correspond with you about it. If your
                complaint reasonably requires us to notify some other person, we
                may decide to give to that other person some of the information
                contained in your complaint. We do this as infrequently as
                possible, but it is a matter for our sole discretion whether we
                do give information, and if we do, what that information is.
                SimlessPay Ltd may also compile statistics showing information
                obtained from this source to assess the level of service we
                provide, but not in a way that could identify you or any other
                person. If a dispute is not settled then we hope you will agree
                to attempt to resolve it by engaging in good faith with us in a
                process of mediation or arbitration.
              </p>
            </section>
            <section>
              <h3>Retention period</h3>
              <p>
                Except as otherwise mentioned in this privacy notice, we keep
                your personal data only for as long as required by us:
              </p>
              <ul>
                <li>to comply with regulatory requirement retention period</li>
                <li>to provide you with the services you have requested</li>
                <li>
                  to comply with other law, including for the period demanded by
                  our tax authorities
                </li>
                <li>to support a claim or defence in court</li>
              </ul>
            </section>
            <section>
              <h3>Compliance with the law </h3>
              <p>
                Our privacy policy complies with the law in the United Kingdom,
                specifically with the Data Protection Act 2018 (the ‘Act’)
                accordingly incorporating the EU General Data Protection
                Regulation (‘GDPR’) and the Privacy and Electronic
                Communications Regulations (‘PECR’).
              </p>
            </section>
            <section>
              <h3>Questions</h3>
              <p>
                If you have any questions about this notice or the way that we
                handle your personal data, Please contact us via:
              </p>
              <p>
                Email:{" "}
                <a href="mailto:cs.support@simlesspay.com">cs.support@simlesspay.com</a>
              </p>
              <p>
                Post mail: Cariocca Business Park, 2, Sawley Road Manchester,
                England, M40 8BB
              </p>
              <p>
                We’ll deal with your queries promptly and will respond to you
                within a reasonable time.{" "}
              </p>
            </section>
            <section>
              <h3>Review of this privacy policy </h3>
              <p>
                We shall update this privacy notice from time to time as
                necessary. Any changes we make to our privacy policy in the
                future will be posted on this page. Please check back frequently
                to see any updates or changes to our privacy policy.
              </p>
            </section>
          </div>
        </Grid>
      </Box>
    </div>
  );
};

export default Hero;
