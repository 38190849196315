import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useCallback, useEffect } from "react";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import axios from "axios";
import { decryptData } from "../Decrepted";

const CurrencyConverter = (props) => {
  const [fromCurrency, setFromCurrency] = useState("GBP");
  const [toCurrency, setToCurrency] = useState("NGN");
  const [amount, setAmount] = useState("1");
  const [convertedAmount, setConvertedAmount] = useState("");
  const [conversionRate, setConversionRate] = useState(null);
  const [data1, setdata] = useState(null);
  const [Drptdata, setDecryptedData] = useState(null);

  const fromCurrencies = [
    { value: "GBP", label: "£", country: "GB" },
    // Add more currencies if needed
  ];

  const toCurrencies = [
    { value: "NGN", label: "₦", country: "NG" },
    // Add more currencies if needed
  ];

  // Dummy conversion rates for example purposes
  const rates = {
    "GBP-NGN": 820, // Example rate
    // Add more rates as needed
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/smlWebsiteApi/payout_channels/list`,
          {
            headers: {
              "api-key": process.env.REACT_APP_SECRET_IV,
            },
          }
        );

        setdata(response?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data1) {
      const decryptedData = decryptData(data1?.result);
      const result = decryptedData.find(
        (item) =>
          item.source_currency === "GBP" && item.target_currency === "NGN"
      );
      setConversionRate(result.conversion_rate);
      handleConversion(1);
    }
  }, [data1]);

  const handleConversion = useCallback(
    (amount) => {
      let ca = (parseFloat(amount) * conversionRate).toFixed(2);
      setConvertedAmount(ca);
    },
    [conversionRate]
  );

  // Handle amount change
  const handleAmountChange = (e) => {
    const newAmount = e.target.value;
    setAmount(newAmount);
    if (newAmount === "") {
      setConvertedAmount("");
      return;
    }
    handleConversion(newAmount);
  };

  // Handle converted amount change
  const handleConvertedAmountChange = (e) => {
    const newConvertedAmount = e.target.value;
    setConvertedAmount(newConvertedAmount);
    if (newConvertedAmount === "") {
      setAmount("");
      return;
    }
    const rate = rates[`${fromCurrency}-${toCurrency}`];
    if (rate) {
      setAmount((parseFloat(newConvertedAmount) / rate).toFixed(2));
    }
  };

  return (
    <Grid container spacing={2} justifyContent={{ xs: "center", md: "end" }}>
      {/* First Row - Currency Selection and Input */}
      <Grid item xs={8}>
        <Stack direction="row" spacing={2}>
          <Select
            value={fromCurrency}
            onChange={(e) => setFromCurrency(e.target.value)}
            variant="filled"
            IconComponent={() => null}
          >
            {fromCurrencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Stack direction="row" spacing={1}>
                  <img
                    width={24}
                    height={24}
                    src={`https://flagsapi.com/${option.country}/flat/48.png`}
                    alt={option.label}
                  />
                  <Typography>{option.label}</Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
          <TextField
            value={amount}
            onChange={handleAmountChange}
            sx={{
              padding: 0,
              "& .MuiFilledInput-root": {
                marginLeft: 0,
                padding: "0px", // Override default padding
                "& .MuiSelect-root": {
                  padding: "0px",
                  margin: "0px",
                },
              },
              "& .MuiInputBase-input": {
                paddingLeft: "10px", // Set the padding of the input itself
              },
            }}
            variant="filled"
            fullWidth
            type="number"
          />
        </Stack>
      </Grid>

      {/* Sync Button */}
      <Grid item container justifyContent="center" alignItems="center">
        <IconButton color="secondary">
          <SyncAltIcon
            sx={{
              transform: "rotate(90deg)", // Rotate the icon by 90 degrees
            }}
          />
        </IconButton>
      </Grid>

      {/* Second Row - To Currency Selection and Input */}
      <Grid item xs={8}>
        <Stack direction="row" spacing={2}>
          <Select
            value={toCurrency}
            onChange={(e) => setToCurrency(e.target.value)}
            variant="filled"
            IconComponent={() => null}
          >
            {toCurrencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Stack direction="row" spacing={1}>
                  <img
                    width={24}
                    height={24}
                    src={`https://flagsapi.com/${option.country}/flat/48.png`}
                    alt={option.label}
                  />
                  <Typography>{option.label}</Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
          <TextField
            value={convertedAmount}
            onChange={handleConvertedAmountChange}
            sx={{
              padding: 0,
              "& .MuiFilledInput-root": {
                marginLeft: 0,
                padding: "0px", // Override default padding
                "& .MuiSelect-root": {
                  padding: "0px",
                  margin: "0px",
                },
              },
              "& .MuiInputBase-input": {
                paddingLeft: "10px", // Set the padding of the input itself
              },
            }}
            variant="filled"
            fullWidth
            type="number"
          />
        </Stack>
      </Grid>

      {/* Conversion Rate */}
      <Grid item xs={8}>
        <Typography variant="body1" align="center">
          {conversionRate
            ? ` 1 ${fromCurrency} = ${conversionRate} ${toCurrency}`
            : ""}
        </Typography>
      </Grid>

      {/* Third Row - Submit Button */}
      <Grid item xs={8}>
        <Button
          onClick={() =>
            document
              .getElementById("targetElement")
              ?.scrollIntoView({ behavior: "smooth" })
          }
          fullWidth
          variant="contained"
          size="large"
          color="secondary"
        >
          Get Started
        </Button>
      </Grid>
    </Grid>
  );
};

export default CurrencyConverter;
