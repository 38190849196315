import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardMedia, colors, Grid, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import img1 from "../../assets/img/RestAssured_01-1.webp"
import img2 from "../../assets/img/RestAssured_02-1.webp"
import img3 from "../../assets/img/RestAssured_03.webp"

const RestAssured = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const cards = [
        { image: img1, title: 'Your money, your safety', description: 'Our end-to-end encryption ensures your funds are protected at all times. Experience peace of mind knowing your money is secure with us.' },
        { image: img2, title: 'Trust and transparency', description: `We're committed to your financial well-being. Our strict adherence to regulations safeguards your money and builds your trust.` },
        { image: img3, title: 'Real people, real help', description: `Need assistance? Our dedicated customer support team is here for you. Enjoy prompt and friendly support whenever you need it.` },
        // Add more cards here
    ];

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768); // Adjust breakpoint as needed
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Grid my={10}>
            <Grid container justifyContent={"center"} direction={"column"} alignItems={"center"} columnGap={4} marginY={10} >
                <Typography variant='h3' component={'h2'} color={"primary"}> Rest assured</Typography>
                <Typography variant='h5'> Here’s why you can trust SimlessPay with sending your money to Nigeria</Typography>
            </Grid>
            {isSmallScreen ? (
                <Swiper
                    spaceBetween={30}
                    slidesPerView={1.2}
                    pagination={{ clickable: true }}
                >
                    {cards.map((card, index) => (
                        <SwiperSlide key={index}>
                            <Card sx={{ borderRadius: 6, m: 4 }} >
                                <CardMedia sx={{ width: 130 }} component="img" image={card.image} />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {card.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {card.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <Grid container spacing={2}>
                    {cards.map((card, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card sx={{ borderRadius: 6, mb: 4 }}>
                                <CardMedia sx={{ width: 130, padding: 2 }} component="img" image={card.image} />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {card.title}
                                    </Typography>
                                    <Typography variant="body2" >
                                        {card.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Grid>
    );
};

export default RestAssured;
