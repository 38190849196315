import React, { useRef } from "react";
import Hero from "../components/SendMoneyToNigeria/Hero";
import DrwaerComponent from "../components/DrawerComponent";
import ContactForm from "../components/ContactUsPage/ContactForm";
import { Container } from "@mui/material";
import Maps from "../components/ContactUsPage/Maps";
import Footer from "../components/Footer";
import CircularCarousel from "../components/SendMoneyToNigeria/CircularMotion";
import HowMuchItCost from "../components/SendMoneyToNigeria/HowMuchItCost";
import TransferStepper from "../components/SendMoneyToNigeria/Stepper";
import RestAssured from "../components/SendMoneyToNigeria/RestAssured";
import CalltoAction from "../components/CalltoAction";

const SendMoneyToNigeria = () => {
  const heroRef = useRef(null)

  const scrollToHero = () => {
    if (heroRef.current) {
      heroRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div>
      <Container maxWidth="lg">

        <DrwaerComponent />
        <Hero />
        <CircularCarousel />
        <TransferStepper />
        <RestAssured />
        <HowMuchItCost />
        <div id="targetElement">
          <CalltoAction />
        </div>

      </Container>
      <ContactForm />
      <Footer />

    </div>
  );
};

export default SendMoneyToNigeria;
