import React, { useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, IconButton } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import img1 from "../../assets/img/fast_transfer.jpg";
import img2 from "../../assets/img/low_fee.jpg";
import img3 from "../../assets/img/no_hassle.jpg";

const CircularCarousel = () => {
    const images = [
        { src: img1, title: 'Fast Transfers', description: 'Send money to Nigeria within minutes using our secure and reliable platform.', bg: "#020044" },
        { src: img3, title: 'Zero Fees', description: 'Our exchange rates to Naira and USD are always good—and we add zero fees on bank transfers to Nigeria.', bg: "#5aeb82" },
        { src: img2, title: 'No Hassle', description: 'We’re regulated by EU and UK law and protected by top-notch security for your peace of mind.', bg: "#7c59fe" },
    ];

    const [focusedIndex, setFocusedIndex] = useState(0);

    const handleClick = (index) => {
        setFocusedIndex(index);
    };

    const calculateAngle = (index) => {
        const angle = ((index - focusedIndex + images.length) % images.length) * (2 * Math.PI) / images.length;
        return angle;
    };

    const circleRadius = 150;

    const DetailedComponent = ({ selectedImage }) => {
        return (
            <Box sx={{ position: 'absolute', top: 0, right: 200, width: '300px', height: '55%', backgroundColor: selectedImage.bg, borderRadius: "10%" }}>
                {/* <img src={selectedImage.src} alt={selectedImage.title} style={{ width: '100%', height: '300px', objectFit: 'cover' }} /> */}
                <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, padding: '20px', backgroundColor: 'rgba(255, 255, 255, 0.1)', backdropFilter: 'blur(20px)', borderRadius: 6 }}>
                    <Typography variant="h3" component="h2" color={"white"} >{selectedImage.title}</Typography>

                    <Typography variant="body1" fontWeight={"bold"} sx={{ color: 'white', mt: 2 }}>{selectedImage.description}</Typography>
                </Box>
            </Box>
        );
    };

    return (
        <Grid display={{ xs: "none", md: "block" }}>


            <Box sx={{ position: 'relative', width: '100%', height: '500px', display: 'flex' }}>
                <Box sx={{ position: 'relative', width: '300px', height: '300px' }}>
                    {images.map((image, index) => {
                        const angle = calculateAngle(index);
                        const x = circleRadius * Math.cos(angle);
                        const y = circleRadius * Math.sin(angle);

                        return (
                            <motion.div
                                key={index}
                                style={{
                                    position: 'absolute',
                                    top: `${circleRadius + y}px`,
                                    left: `${circleRadius + x}px`,
                                    width: '80px', // Smaller width for images initially
                                    height: '130px', // Smaller height for images initially
                                    borderRadius: '4%',
                                    overflow: 'hidden',
                                    cursor: 'pointer',
                                    zIndex: index === focusedIndex ? 1 : 0,
                                }}
                                animate={{
                                    scale: index === focusedIndex ? 4 : 1, // Enlarge only on focus
                                }}
                                transition={{ duration: 1, ease: "easeInOut" }}

                                onClick={() => handleClick(index)}
                            >
                                <img src={image.src} alt={image.title} style={{ width: '100%', height: '100%' }} />
                            </motion.div>
                        );
                    })}
                </Box>
                <DetailedComponent selectedImage={images[focusedIndex]} />
            </Box>
        </Grid>
    );
};

export default CircularCarousel;
