import React from "react";
import { Grid, Paper, Box, Typography, Stack, Button } from "@mui/material";
import styled from "styled-components";
import Link from "@mui/material/Link";
import Image from "../assets/img/heroImg.png";
import FirstEllipse from "../assets/img/Ellipse 28.png";
import SecondEllipse from "../assets/img/Ellipse 29.png";
import ThirdEllipse from "../assets/img/Ellipse 30.png";
import EllipseImg from "../assets/img/Ellipse 31.png";
import TextImg from "../assets/img/250k+.png";
import Herologo from "../assets/img/Group 46.png";
import HeroArrow from "../assets/img/heroArrowImg.png";
import WomenImg from "../assets/img/women2.png";
import google from "../assets/img/google2.png";
import appstore from "../assets/img/appstorebadge.png";

const Item = styled(Typography)(({ theme }) => ({
  backgroundColor: "transparent",
}));
const CustomStyledImg = styled("img")({
  "@media (max-width: 900px)": {
    width: "100%",
  },
  width: "70%",
  height: "100%",
  borderRadius: "8px",
  maxWidth: "100%",
});
const ImgStyle = {
  "@media (max-width: 768px)": {
    width: "200px",
  },
};

const HeroSection = () => {
  return (
    <div>
      <Box
        sx={{
          marginTop: { lg: "5%", xl: "3%", md: "7%", xs: "8%" },
          // backgroundColor: "purple",
          paddingTop: { sm: "4rem", xs: "4rem" },
          marginRight: "5%",
          marginLeft: { md: "5%", lg: "10%" },
        }}
      >
        <Grid
          container
          spacing={{ xs: 4, sm: 4, md: 3, lg: 0, xl: 3 }}
          sx={{
            marginLeft: { lg: "5%" },
            maringTop: { sm: 80 },
            // backgrounColor: "green",
            justifyContent: "center",
            alignItems: "center",
            "@media (max-width: 912px)": {
              justifyContent: "center",
              alignContent: "center",
            },
          }}
        >
          <Grid
            item
            xs={12}
            sm={5}
            lg={5}
            md={6}
            sx={{ marginTop: { xl: "10rem" } }}
          >
            <Box
              display={"flex"}
              justifyContent={{ lg: "end", md: "center", sm: "center" }}
              sx={{ ml: { sm: "20%", md: 0 } }}
              padding={{ xs: "0 10%", sm: 0 }}
            >
              <Item>
                <Stack
                  spacing={{ xl: 4, lg: 2, md: 2, xs: 4 }}
                  sx={{
                    marginBottom: { xl: "15%", lg: "15%", md: "25%", xs: "5%" },

                    "@media (min-width: 2560px)": {
                      marginBottom: "7%",
                    },
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      lineHeight: "120%",
                      fontWeight: 900,
                      color: "#fff",

                      fontSize: { xl: 90, md: 40, xs: 30, lg: 60 },
                      "@media(max-width: 321px)": {
                        fontSize: 25,
                      },
                      fontFamily: "IBM Plex Sans",
                    }}
                  >
                    The world is within your reach!
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      lineHeight: "150%",

                      color: "#ffffff",
                      width: { lg: "195px", sm: "250px", xl: "350px" },
                      fontSize: { lg: "14px", xl: "25px", md: 20, xs: "15px" },

                      fontFamily: "Montserrat",
                      fontWeight: 200,
                    }}
                  >
                    Send money with Simlesspay with Zero Fees
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  spacing={{ xl: 10 }}
                  justifyContent={"i"}
                  alignItems={"flex-start"}
                  // sx={{ backgroundColor: "green" }}
                  justifySelf={"center"}
                  justifyItems={"center"}
                >
                  <Stack direction={"row"}>
                    <Box sx={{ marginRight: "-10%" }}>
                      <Box
                        component="img"
                        sx={{
                          height: { xs: 46, sm: 56, xl: 100 },
                          width: { xs: 46, sm: 56, xl: 100 },
                          maxHeight: { xs: 233, md: 167, xl: 200 },
                          maxWidth: { xs: 350, md: 250, xl: 300 },
                        }}
                        alt="The house from the offer."
                        src={FirstEllipse}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginRight: "-10%",
                        "@media (max-width: 500px)": {
                          minWidth: "5%",
                        },
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          height: { xs: 46, sm: 56, xl: 100 },
                          width: { xs: 46, sm: 56, xl: 100 },
                          maxHeight: { xs: 233, md: 167, xl: 200 },
                          maxWidth: { xs: 350, md: 250, xl: 300 },
                        }}
                        alt="The house from the offer."
                        src={SecondEllipse}
                      />
                    </Box>
                    <Box sx={{ marginRight: "-10%", maxWidth: "100%" }}>
                      <Box
                        component="img"
                        sx={{
                          height: { xs: 46, sm: 56, xl: 100 },
                          width: { xs: 46, sm: 56, xl: 100 },
                          maxHeight: { xs: 233, md: 167, xl: 200 },
                          maxWidth: { xs: 350, md: 250, xl: 300 },
                        }}
                        alt="The house from the offer."
                        src={ThirdEllipse}
                      />
                    </Box>
                    <Stack
                      sx={{
                        marginLeft: {
                          xs: "10%",
                          sm: "10%",
                          md: "15%",
                          lg: "10%",
                        },
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          lineHeight: 1,
                          background:
                            "linear-gradient(98deg, #C3F25E -6.48%, #C3F25E 10.35%, #4AE076 36.38%, #7E5DF5 61.9%, #7E5DF5 79.59%)",
                          fontSize: { lg: "28px", xl: "50px" },

                          WebkitTextFillColor: "transparent",
                          backgroundClip: "text",
                        }}
                      >
                        {" "}
                        250k+
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          lineHeight: 2,
                          fontWeight: { sm: 200, xl: 300, lg: 300, md: 300 },
                          color: "#fff",
                          fontSize: { sm: 10, xl: 35, lg: 14, md: 15, xs: 7 },
                        }}
                      >
                        Active Users
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction={"row"} justifyContent={"start"}>
                  <Link
                    href="https://play.google.com/store/apps/details?id=com.newsimlesspay"
                    target="_blank"
                  >
                    <Box
                      component="img"
                      sx={{
                        height: { sm: 30, xl: 50 },
                        width: { xs: 50, sm: 100, xl: 150 },
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                        mr: 2,
                      }}
                      alt="The house from the offer."
                      src={google}
                    />
                  </Link>
                  <Link
                    href="https://apps.apple.com/pk/app/simlesspay-money-transfer/id6466781453"
                    target="_blank"
                  >
                    <Box
                      component="img"
                      sx={{
                        height: { sm: 30, xl: 50 },
                        width: { xs: 50, sm: 100, xl: 150 },
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                      }}
                      alt="The house from the offer."
                      src={appstore}
                    />
                  </Link>
                  <Stack>
                  </Stack >
                  <div
                    className="trustpilot-widget" data-locale="en-GB" data-template-id="5406e65db0d04a09e042d5fc" data-businessunit-id="6582f1d75dd27d7a5aa2f78d" data-style-height="28px" data-style-width="100%" data-theme="dark" style={{
                      color: "white",
                      marginTop: 12
                    }}>
                    <a href="https://uk.trustpilot.com/review/simlesspay.com" target="_blank" rel="noopener">Trustpilot</a>
                  </div>
                </Stack>
              </Item>
            </Box>
          </Grid>

          <Grid
            item
            sm={7}
            xs={12}
            lg={7}
            md={6}
            sx={{
              //backgroundColor: "yellow",
              "@media (max-width: 900px)": {},
            }}
          >
            <Item
              pt={{ sm: 8, xs: 4 }}
              sx={{
                padding: { xs: "0 5% 45% 5%", sm: 0 },

                "@media(max-width: 450px)": {
                  padding: { xs: "0 5% 40% 15%", sm: 0 },
                },
                "@media(max-width: 430px)": {
                  padding: { xs: "0 5% 60% 8%", sm: 0 },
                },
                "@media(max-width: 320px)": {
                  padding: { xs: "0 2% 60% 10%", sm: 0 },
                },
                paddingLeft: { xs: "60px", sm: 0 },
              }}
            >
              <Box
                component="img"
                sx={{
                  height: { sm: 450, md: 400, lg: 550, xl: 850, xs: 300 },
                  width: { lg: 550, sm: 390, xl: 650, xs: 300, md: 450 },
                  maxHeight: { xs: 2500, xl: 2000 },
                  maxWidth: { xs: 2550, xl: "100%" },
                  ml: { lg: 8 },
                  resizeMode: "contain",
                  "@media (max-width: 320px)": {
                    width: { xs: 250 },
                    height: { xs: 250 },
                  },
                  "@media (max-width: 300px)": {
                    width: { xs: 200 },
                    height: { xs: 200 },
                  },
                }}
                alt="The house from the offer."
                src={Image}
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Grid item xs={12}>
        <Box
          sx={{
            marginTop: { xs: "-55%", sm: "-20%", md: "-10%" },
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          <Stack
            sx={{
              marginBottom: { lg: 10 },
              // backgroundColor: "yellowgreen",
              marginTop: { lg: "-10%" },
            }}
          >
            <Box
              sx={{
                width: "12%",
                height: "10",
                display: "flex",
                justifyContent: "center",
                // backgroundColor: "purple",
                // alignContent: "center",
                alignSelf: "center",

                marginBottom: {
                  md: "-15%",
                  lg: "-11.3%",
                  xl: "-16.3%",
                  sm: "-20%",
                  xs: "-35%",
                  "@media(min-width: 1900px)": {
                    marginBottom: {
                      xl: "-16.3%",
                    },
                  },
                },
              }}
            >
              <Box
                component="img"
                sx={{
                  "@media (max-width: 425px)": {
                    display: "none",
                  },
                  height: { sm: 238, xs: 180 },
                  width: { sm: 418, xs: 200 },
                  minHeight: { sm: 90, md: 100, lg: 100, xs: 100, xl: 500 },
                  minWidth: { sm: 300, lg: 400, md: 400, xs: 200, xl: 800 },
                  marginTop: { xl: "90%", md: "30%", sm: "20%" },
                }}
                alt="The house from the offer."
                src={EllipseImg}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                // backgroundColor: "black",
                //giving margin Top for md
                marginTop: { lg: 0, xl: 0 },
                flexWrap: "wrap",
                justifyContent: "center",
                "& > :not(style)": {
                  m: 1,
                  width: { md: 1900, sm: 700, xs: 350 },
                  height: {
                    xl: 750,
                    lg: 450,
                    md: 300,
                    sm: 300,
                    xs: 220,
                  },
                  position: "relative",
                },
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  padding: { xl: 0, lg: 0, md: 4 },
                  borderRadius: "50px",
                  "@media (max-width: 425px)": {
                    display: "none",
                  },

                  marginBottom: {
                    lg: "100px",
                    xl: "100px",
                    md: "100px",
                    sm: "200px",
                    xs: "100px",
                  },
                  // backgroundColor: "green",
                }}
              >
                <Box
                  sx={{
                    zIndex: 2500,
                    display: "flex",
                    justifyContent: "center",
                    marginTop: {
                      xl: "-2%",
                      lg: "-6%",
                      md: "-12%",
                      sm: "-12%",
                      xs: "-10%",
                      "@media (min-width: 1500px)": {},
                    },

                    // backgroundColor: "yellow",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: { sm: 100, xs: 60, xl: 200, lg: 130 },
                      width: { sm: 100, xs: 60, xl: 200, lg: 140 },
                      maxHeight: { sm: 100000, xl: 1000 },
                      maxWidth: { sm: 100000, xl: 1000 },

                      zIndex: 2500,
                    }}
                    alt="The house from the offer."
                    src={Herologo}
                  />
                </Box>
                <Grid
                  container
                  spacing={{ xl: 4 }}
                  sx={{
                    justifyContent: { sm: "center", xs: "space-between" },
                    alignSelf: { sm: "center", xs: "space-between" },
                    marginTop: {
                      xl: "7%",
                      lg: "7%",
                      md: "4%",
                      sm: 0,
                      xs: "3%",
                    },
                  }}
                  height={100}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      xs: { flexDirection: "column" },
                      alignItems: "center",
                      "@media (max-width: 900px)": {
                        justifyContent: "space-between",
                        alignItems: "space-between",
                      },
                    }}
                  >
                    <Grid
                      item
                      sm={6}
                      lg={6}
                      md={6}
                      xs={12}
                      sx={{
                        backgroundColor: "yellow",
                      }}
                    >
                      <Grid
                        spacing={{ xl: 70 }}
                        width={50}
                        container
                        sx={{
                          justifyContent: "center",
                          alignSelf: "center",
                          alignItems: "center",
                          justifyItems: "center",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={6}
                          sx={{
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            alignSelf: "center",
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              "@media (max-width: 375px)": {
                                // backgroundColor: "green",
                              },
                              width: { sm: "min-content", xs: 60 },
                              height: {
                                sm: 230,

                                lg: 330,
                                md: 300,
                                xs: 130,
                                xl: 650,
                              },

                              maxHeight: { xs: 1033 },
                              maxWidth: { xs: 1000 },
                            }}
                            alt="The house from the offer."
                            src={HeroArrow}
                            position={"absolute"}
                            top={{ lg: 105, md: 50, sm: 40, xs: 50, xl: 20 }}
                            left={0}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          md={6}
                          lg={6}
                          maxWidth={"100%"}
                          sx={{
                            "@media (max-width: 900px)": {
                              height: "10%",
                            },
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              height: {
                                sm: 270,
                                xl: 650,
                                lg: 400,
                                md: 300,
                                xs: 130,
                              },
                              width: { sm: "min-content", xs: 80 },
                              maxHeight: { sm: 700 },
                              maxWidth: { sm: 700 },
                            }}
                            alt="The house from the offer."
                            src={WomenImg}
                            position={"absolute"}
                            top={{ lg: 45, md: 40, sm: 20, xs: 60 }}
                            left={{
                              md: 160,
                              lg: 220,
                              sm: 130,
                              xs: 10,
                              xl: 400,
                            }}
                            zIndex={100}
                            marginLeft={{ xs: 5, sm: 0 }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      sx={{
                        mt: { sm: 15, md: 10, lg: 10, xl: 10, xs: 7 },
                      }}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box sx={{ width: { lg: "60%", sm: "80%", xl: "90%" } }}>
                        <Stack
                          direction={"column"}
                          spacing={{ xl: 2, lg: 1, md: 2, sm: 1, xs: 1 }}
                          marginTop={{ xs: 1, md: 10, lg: 10, xl: "10%" }}
                          marginLeft={{ xl: "10%" }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              padding: {
                                sm: "7.447px 14.894px",
                                xs: " 3.447px 7.894px",
                                marginTop: { xl: 20 },
                              },

                              "&:active": {
                                backgroundColor: "#C7FA56",
                                color: "	#1A1110",
                              },
                              "&:hover": {
                                backgroundColor: "#C7FA56",
                                color: "	#1A1110",
                              },

                              backgroundColor: "#C7FA56",
                              color: "	#1A1110",

                              fontSize: { xl: "35px", sm: "13px", xs: "9px" },
                              fontWeight: 700,
                              display: "flex",
                              alignSelf: "start",

                              borderRadius: "14px",
                              "@media (max-width: 900px)": {
                                marginTop: "3rem",
                                marginBottom: "2rem",
                              },
                            }}
                          >
                            SimlessPay Features
                          </Button>
                          <Item
                            variant="h3"
                            sx={{
                              fontFamily: "IBM Plex Sans",
                              gap: { md: "26px", sm: "20px" },
                              color: "#020335",
                              fontWeight: 700,
                              "@media (max-width: 900px)": {
                                marginBottom: "2rem",
                                // textAlign: "center",
                                lineHeight: 1.5,
                                textShadow:
                                  "2px 2px 4px rgba(54, 50, 50, 0.55)",
                              },
                              fontSize: {
                                lg: 40,
                                xl: 60,
                                md: 20,
                                sm: 20,
                                xs: 10,
                              },
                            }}
                          >
                            Make Instant money transfer for free
                          </Item>
                          <Item
                            variant="subtitle1"
                            sx={{
                              fontFamily: "Montserrat",
                              fontWeight: 200,
                              lineHeight: 1.2,

                              fontSize: {
                                xl: 30,
                                lg: 15,
                                md: 15,
                                sm: 10,
                                xs: 9,
                              },
                              color: "#344054",
                              "@media (max-width: 900px)": {
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: 20,

                                // textAlign: "center",
                              },
                            }}
                          >
                            SimlessPay makes it easy to transfer the money with
                            no fee and exchange rate's are very low to make the
                            transaction easy for customers
                          </Item>
                        </Stack>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Paper>
            </Box>
          </Stack>
        </Box>
      </Grid>
    </div>
  );
};

export default HeroSection;
