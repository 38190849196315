// import React from "react";
// import logo from "./logo.svg";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import "./App.css";
import Navbar from "./components/Navbar";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { appTheme } from "./theme/theme";
import HeroSection from "./components/HeroSection";
import { Box, Button, Paper } from "@mui/material";
import Image from "./assets/img/backgroundImg.png";

import Features from "./components/Features";
import Working from "./components/Working";
import Reviews from "./components/Reviews";
import CalltoAction from "./components/CalltoAction";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Blog from "./pages/Blog";
import FAQ from "./pages/FAQ";
import ContactUsPage from "./pages/ContactUsPage";
import DrwaerComponent from "./components/DrawerComponent";
import { lime, purple } from "@mui/material/colors";
import ProductPage from "./pages/ProductPage";
import HowItWorks from "./pages/HowItWorks";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import BlogDetails from "./pages/BlogDetails";
import SendMoneyToNigeria from "./pages/SendMoneyToNigeria";

const styles = {
  paperContainer: {
    backgroundImage: `url(${Image})`,
    paddingBottom: "5rem",
    backgroundSize: "cover",
  },
};
const theme = createTheme({
  palette: {
    primary: lime,
    secondary: purple,
  },
});

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={appTheme}>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogDetails />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/privacy-policy" element={<HowItWorks />} />
            <Route path="/contact" element={<ContactUsPage />} />
            <Route path="/send-money-to-nigeria" element={<SendMoneyToNigeria />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
